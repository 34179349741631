import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '../Text';

const StyledProjectThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 0;
  pointer-events: none;
  animation: zoomReverse 600ms forwards;
  &:last-child {
    margin-bottom: 0;
    ${({ theme }) => theme.breakpoints.phablet`
      margin-right: 0;
      margin-bottom: 0;
    `}
  }
  ${({ theme }) => theme.breakpoints.phablet`
    &:hover {
      cursor: pointer;
      animation: zoom 600ms forwards;
    }
  `}

  .project-thumbnail__container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0;
    color: ${({ theme }) => theme.colors.white_1};
  }
  .project-thumbnail__footer {
    display: flex;
    flex: 1 0 100%;
    align-items: center;
    justify-content: center;
    height: ${({ theme }) => theme.rhythmSizing(4)}rem;
    /* border-top: 1px solid ${({ theme }) => theme.colors.pink_1}; */
    z-index: 99;
    pointer-events: all;
  }
  .project-thumbnail__body {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    padding: 1px;
    margin: 1px;
    border-radius: ${rem(`12px`)};
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    border: solid 1px transparent;
    background: ${({ theme }) => `linear-gradient(
      265deg,
      ${theme.colors.blue_1_65},
      ${theme.colors.blue_1},
      ${theme.colors.blue_1_90}
    )`};
    ::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: inherit;
      margin: -2px;
      z-index: -1;
      background: ${({ theme }) => `linear-gradient(
          -30deg,
          ${theme.colors.blue_1_30},
          ${theme.colors.blue_1_65},
          ${theme.colors.pink_2}
        )`};
    }
  }
  .project-thumbnail__image {
    align-items: center;
    justify-content: center;
    pointer-events: all;
    position: relative;
  }
  @keyframes bg {
    50% {
      background-position: 100% 100%;
    }
  }
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  @keyframes zoomReverse {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes reveal {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes revealReverse {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export interface IProjectThumbnail {
  className?: string;
  title: string;
  image: any;
  alt: string;
  linkTo: string;
}

export const ProjectThumbnail = ({
  className,
  title,
  image,
  alt,
  linkTo,
}: IProjectThumbnail) => (
  <StyledProjectThumbnail className={className}>
    <div className="project-thumbnail__container">
      <div className="project-thumbnail__body">
        <Link aria-label={alt} to={linkTo}>
          <GatsbyImage
            image={image}
            alt={alt}
            className="project-thumbnail__image"
          />
        </Link>
      </div>
      <div className="project-thumbnail__footer">
        <Text noRhythem type="body1" tag="span">
          {title}
        </Text>
      </div>
    </div>
  </StyledProjectThumbnail>
);
